// 	                                          
// 	  ###    ###   ##      ###   ####    ###  
// 	 ## ##  ## ##  ##     ## ##  ## ##  ## ## 
// 	 ##     ## ##  ##     ## ##  ## ##  ##    
// 	 ##     ## ##  ##     ## ##  ####    ###  
// 	 ##     ## ##  ##     ## ##  ###       ## 
// 	 ## ##  ## ##  ##     ## ##  ####   ## ## 
// 	  ###    ###   #####   ###   ## ##   ###  
// 	                                          
// 	                  
                        
$primary-color: #34253B;
$secondary-color: #FF6114;
$link-color:#FF6114;
$link-hover-color:#34253B;
$gray-text: #999;


$success-color: #28dfae;
$warning-color: #ffae00;
$alert-color: #ec5840;

$black-lighter:#333;
$black-light:#151515;
$black: #111;
$black-dark:#050505;
$black-darker:#000;


$gray-lighter: #f8f8f8;
$gray-light: #f5f5f5;
$gray: #eee;
$gray-dark: #ddd;
$gray-darker: #ccc;



$white: #fff;


$blue: #11c0f2;
$blue-lighter:scale-color($blue, $lightness: 60%);
$blue-light:scale-color($blue, $lightness: 30%);
$blue-dark:scale-color($blue, $lightness: -30%);
$blue-darker:scale-color($blue, $lightness: -60%);



$facebook-color:#3B5998;
$twitter-color:#55acee;
$yt-color: #cd201f;



//                                                                           
//      ####     #     ###   ## ##   ###   ####    ###   ## ##  #  ##  ####  
//      ## ##   ###   ## ##  ## ##  ## ##  ## ##  ## ##  ## ##  ## ##  ## ## 
//      ## ##  ## ##  ##     ####   ##     ## ##  ## ##  ## ##  #####  ## ## 
//      ####   ## ##  ##     ###    #####  ####   ## ##  ## ##  #####  ## ## 
//      ## ##  #####  ##     ####   ## ##  ###    ## ##  ## ##  #####  ## ## 
//      ## ##  ## ##  ## ##  ## ##  ## ##  ####   ## ##  ## ##  ## ##  ## ## 
//      ####   ## ##   ###   ## ##   ####  ## ##   ###    ###   ##  #  ####  
//                                                                           
//                                                                           


.bg{

    //BLACK

    &--black-lighter{
        background: $black-lighter;
    }
    &--black-light{
        background: $black-light;
    }
    &--black{
        background: $black;
    }
    &--black-dark{
        background: $black-dark;
    }
    &--black-darker{
        background: $black-darker;
    }

    //GRAY

    &--gray-lighter{
        background: $gray-lighter;
    }
    &--gray-light{
        background: $gray-light;
    }
    &--gray{
        background: $gray;
    }
    &--gray-dark{
        background: $gray-dark;
    }
    &--gray-darker{
        background: $gray-darker;
    }

    &--white{
        background:$white;
    }

}

.white{
    color:$white !important;
}

.black{
    color:$black !important;
}

