main{
    margin-top:20px !important ;
}
.taby{


    position: fixed;
    display: flex;
    justify-content: right;
    align-items: center;
   
    height: 70px;
    z-index: 112;
    background:white;
    bottom:0;
    width:100%;
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    @include breakpoint(medium){
       top:0;
       bottom: initial;
       height: 50px;
    }
   &__text{
    margin-left:30px;
        margin-right: auto;
        // align-self: flex-start;
        display: none;
        @include breakpoint(medium){
            display: block;
        }
       
    }


}

.tab {
    background-color: grey;
    padding: 0.75rem;
    font-size:13px;
    color: #fff;
    cursor: pointer;
    height: 100%;
    flex-grow: 1;
    border-right:2px solid black;

    @include breakpoint(medium){
        display: block;
        font-size:16px;
        flex-grow: 0;
    }
    span{
        pointer-events: none;
        // z-index: -1;
    }
  }
  

  .selected {
    background-color: black;
  }
  
  
  .tab-panel {



  }
  
  .hidden {
    display:none;
  }



.prace-wrapper{
    background: #f5f5f5;
  }

.prace{
  display: flex;
  flex-direction: column;
  

  background:#f5f5f5;
  padding-bottom:120px;
}
.praca{
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  display: flex;
  align-items: flex-start;

    flex-wrap: wrap;
  padding:1vw;
  margin:3vw 2vw;
  background: white;
  max-width: 1200px;
  overflow:hidden;
  position: relative;
}
.praca h1{
  margin-bottom:5px;
}

.praca__miejsce{
  position:absolute;
  top:0;
  left:0;
  padding: 10px;
  background:white;
}

.praca.propozycja{
  border: 3px solid yellow;
}


a{text-decoration:none;}
.praca__image{
 max-width:600px;
 min-width:360px;
  margin-right:1vw;

}

.praca__image img{
max-width:100%;
max-height: 40vh;
}

.praca__id{
 font-size:9px;

}
.praca__zoom{
  text-align:right;
  text-decoration:none;
  color:black;
  font-size:14px;
  margin-top:-35px;
  margin-right:15px;
  background: rgba(255,255,255,0.4);
}

.praca__tytul-plus-like{
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin-top:20px;
}


.praca__tytul{
 font-size:12px;

margin-left:11px;
margin-top:2px;
margin-right:15px;
color:#999;


}
.praca__instagram{
  border:solid 1px #E1306C;
  color:#E1306C;
  padding:5px 20px;
  margin:20px 0;
  text-decoration:none;
  border-radius:5px;
  font-size:14px;
  margin-top:20px;
  margin-bottom:0;
  display:inline-block;
}

.praca__portfolio{
   border:solid 1px #444;
  color:#444;
  padding:5px 20px;
  margin:5px 0;
  text-decoration:none;
  border-radius:5px;
  font-size:12px;

  display:inline-block;
}


.praca__info{
flex:1 0 auto;
max-width: 500px;
padding:10px;
margin-top:-25px;
@include breakpoint(medium){
    padding:15px;
}
}

.praca__info h1{
  font-weight: bold;
  color:#222;
  font-size: 24px;
  margin-top:15px;
}


h3{
  font-size:12px;
  font-weight: bold;
  color:#aaa;
  margin-bottom:0.3vw;

}

.praca__info p{
margin:0;

}

.praca__bio-container{
  
  max-width: 90vw;
  margin-top:20px;
  @include breakpoint(medium){
    margin-top:30px;
  }
}

.praca__bio{
  max-width: 90vw;
 
  font-size:13px;
  font-weight: 200;
  line-height:1.5;
  @include breakpoint(large){
    font-size:14px;
  }
 
}

.todo-complete-icon{
        width: 30px;
        height: 30px;
        border: 2px solid #ccc;
        border-radius: 50%;
        margin-right: 20px;
        margin-top: 10px;
        position: relative;
        cursor: pointer;
    }
    .todo-complete-icon:hover{
border-color: #00c996;
    }


    .todo-complete-icon.completed{
        border-color: #00c996;
    }
    .todo-complete-icon.completed:before{
        content: "\2714";
        font-size: 36px;
        position: absolute;
        top:-10px;
        left:4px;
        color: #00c996;
    }



   .lajk-btn{

    margin-top:5px;
    background: none;
   color:transparent;
   cursor: pointer;
    position: relative;
    width:40px;
        height:40px;
        border:none;
        @include breakpoint(medium){
            position: absolute;
        top: 10px;
        right: 21px;
            
 }

         
        background:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>heart-outline</title><path fill="grey" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" /></svg>');
       
        


    }
    .lajk-btn:hover:before{
         position: absolute;
        top:0;
        left:0;
        opacity: 1;
        width:40px;
        height:40px;
     
        content: '';
        background:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>heart</title><path fill="red" d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z" /></svg>');

    }
    .lajk-btn.active{
        background:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>heart</title><path fill="red" d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z" /></svg>');
        }

        .lajk-btn.active:hover:before{
            background:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>heart-outline</title><path fill="grey" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" /></svg>');
            }

        .wybrane-prace  .odrzuc-btn{
            display:none;

        } 

        .odrzucone-prace  .lajk-btn{
            display:none;

        } 


    .lajk-btn:before{
        position: absolute;
        opacity: 0;
        top:0;
        left:0;
        width:20px;
        height:20px;
        background: red;
        content: '';
        }
        
        .lajk-btn:after{
            // position: absolute;
            // top:0;
            // left:0;
            // width:20px;
            // height:20px;
            // background: red;
            // border:3px solid red;
            // content: '';
            }