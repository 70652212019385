//                                                                               
//  ######   ###   ####          ## ##  #####    #    ####   #####  ####         
//    ##    ## ##  ## ##         ## ##  ##      ###   ## ##  ##     ## ##        
//    ##    ## ##  ## ##         ## ##  ##     ## ##  ## ##  ##     ## ##        
//    ##    ## ##  ####          #####  ####   ## ##  ## ##  ####   ####         
//    ##    ## ##  ##            ## ##  ##     #####  ## ##  ##     ###          
//    ##    ## ##  ##            ## ##  ##     ## ##  ## ##  ##     ####         
//    ##     ###   ##            ## ##  #####  ## ##  ####   #####  ## ##        
//                                                                               
//
//    top-nav-1.0-beta
//
// 
//
//
//
//

.hamburger{
    display:none !important;
}

$menu-mobile-width: $lg;
$menu-font-family: $font-2;
$header-background: transparent;
$header-background-mobile: white;
$header-background-black: white;

//first level
$menu-text-color: #fff;
$menu-text-color-mobile: $ciemny;
$menu-text-color-white: #fff;
$menu-text-color-hover: $rozowy;
$menu-background: transparent;
$menu-background-hover: transparent;

//sub menu
$menu-sub-text-color: $ciemny;
$menu-sub-text-color-hover: $ciemny;
$menu-sub-background: #eee;
$menu-sub-background-hover: #e5e5e5;

.top-header {
    background: $header-background;
    width: 100%;
    transition: top 0.3s ease-in-out;
   display: flex;
   justify-content: center;
    z-index: 111;
    position: relative;
    @include breakpoint($menu-mobile-width down) {
        position:fixed; 
        top:0;
     
    }
    
}


.header-wrapper{
    margin-top:3.5vw;
    width: calc(100% - 14vw);
  }
  

.nav-wrapper{
    height: 60px;
    display: flex;
    background: $header-background;
    
    justify-content: center;
    @include breakpoint($menu-mobile-width) {
        height: 150px;      
    }
}

.nav {
    width: 100%;		    
	font-family: $menu-font-family;    
    display: flex;
    align-items: center;
    background: $header-background;
    justify-content: space-between;
    transition: background 0.1s ease;
    

    @include breakpoint($menu-mobile-width) {
        align-items: center;
    //      width: calc(100% - 1.875rem);	
    //   padding-right: 2%;

    }


    .hamburger {
        margin-top: 4px;
        @include breakpoint($menu-mobile-width up) {
            display: none;
        }
    }

    &__logo {

        

        @include breakpoint($menu-mobile-width up) {
           

        }
    }

    &__logo--big{		
        @include breakpoint($menu-mobile-width down) {		
              display: block;
              margin:0.5rem 0;		
        }		
        @include breakpoint($menu-mobile-width up) {		
            display: block;		
        }		
        img, svg, path{		
             fill:$menu-text-color;	
        }		
    }		
    &__logo--small{		
        @include breakpoint($menu-mobile-width up) {		
            display: none;		
        }		
        @include breakpoint($menu-mobile-width down) {		
            display: block;		
        }		
        img, svg, path{		
            fill:#333;		
        }		
    }

    &__logo img, &__logo svg {
        height: 35px;
        width: auto;
        margin-top: 3px;
        display: block;

        @include breakpoint($menu-mobile-width up) {
            height: 45px;
        }
         @include breakpoint($lg) {
            height: 70px;
        }
    }
    &__lang {
        list-style-type: none;
        display: flex;
        position: absolute;
        right:0;
        top:0;
        margin:0 0.3em 0 0;
        li{

        }
        li a{
            font-size:0.65em;
            margin-left:0.5em;
            color:$black;
        }
        &--white{
            li a{
                
                color:$white;
            } 
        }
    }
    //menu desktop
    &__menu {
        list-style: none;
        margin: 0 0;
        padding: 0 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: $header-background-mobile;
        width: 100vw;
        text-align: center;
        border-bottom: 1px solid $gray;
        position: absolute;
        left: 0;
        top:0;
        transition: transform 0.3s ease;
        transform: translateY(-100%);
        
        z-index: -1;
     
        height:100vh;
         
        @include breakpoint($menu-mobile-width) {
            border: none;
            background: $header-background;
            flex-direction: row;
            position: static;
            transform: initial;
            width: initial;
            z-index: initial;
            padding:0 0;
            height: 100%;
             align-items: center;
        }
         & > li > a{
 
            transition:opacity 0.3s ease;
        }
        &:hover > li > a{
            opacity:1;
           
            transition:opacity 0.3s ease;
        }


        & > li {
            margin: 0 0;
            position: relative;

            @include breakpoint($menu-mobile-width) {
                margin: 0 0 0 30px;
            }


            @include breakpoint(large) {
                margin: 0 0 0 45px;
            }


            & > a {
                padding: 1.2rem 0;
                text-decoration: none;
                color: $menu-text-color-mobile;

                // letter-spacing: .5px;

                font-size: 1.6rem;
             
                background: $menu-background;
                display: block;
//                 border-top: 1px solid $gray;
                position: relative;
                transition:color 0.3s ease;
                @include breakpoint($menu-mobile-width) {
                    border: none;
                    padding: 10px 10px 8px 10px;
                    color: $primary-color;
                    
                }


                @include breakpoint(900px) {
                    font-size: 0.95rem;
                    letter-spacing:0.05em;
                }


                &:hover {
                    opacity:1;
                    transition:color 0.3s ease;
                    // color: $menu-text-color-hover;
                     color:$secondary-color;
                    background: $menu-background-hover;
                }
            }
            &.active a{
                color:$secondary-color;
            }

            & > a > span {
                position: relative;
            }

            & > a > span:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: -0.4em;
                left: 0;
                background-color: $menu-text-color;
                visibility: hidden;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                transform-origin: left top;
                -webkit-transition: all 0.2s ease-in-out 0s;
                transition: all 0.2s ease-in-out 0s;

                @include breakpoint($menu-mobile-width down) {
                    display: none;
                }
            }

            & > a:hover > span:before, &.active > a > span:before, &.selected > a > span:before {
                background-color: $menu-text-color;
                visibility: visible;
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
            }

           

            &.top-item {
                margin: 0 0;
            }

            &.top-item > a {
                background: $gray;
                padding: 5px 10px;
                font-size: 10px;
                transition: background 0.2s ease;
            }

            &.top-item > a:hover {
                background: $gray-dark;
            }

            &.selected a {
                @include breakpoint($menu-mobile-width up) {
                    border-bottom: 2px solid $primary-color;
                    margin-left: 15px;
                    padding-left: 0;
                    margin-right: 15px;
                    padding-right: 0;
                    padding-bottom: 5px;
                }
            }

            /* item>a dla elementow zagniezdzonych*/
            
            //nie wyswietlaj menu jak ma klase hide
            &.hide-sub-menu .nav__sub-menu{
                display: none;
            }
             //nie wyswietlaj menu tylko dla duzych ekranow, na mobilnych bedzie widoczne
            &.hide-sub-menu-large .nav__sub-menu{
                 @include breakpoint($menu-mobile-width up) {
                     display: none;
                 }
                a{
                    background:transparent;
                    color:#fff;
                 }
            }

            &:not(.show-sub-menu-on-subpages) .nav__sub-menu {
                @include breakpoint($menu-mobile-width up) {
                     transform: translate(-50%, -100%);
                 }
            }

            // &.is-hover .nav__sub-menu {
            //     @include breakpoint($menu-mobile-width up) {
            //         display: flex;
            //         transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
            //         transform: translate(-50%, 100%);
            //     }
            // }
            
            //mouseleave delay
            &.has-children .nav__sub-menu{
                transition-delay: 0.3s;
            }

            &.has-children.active:not(.hide-sub-menu) .nav__sub-menu{
                @include breakpoint($menu-mobile-width down) {
                     display: block;
                }
            }

            
            //pokaz na hoverze
            &.has-children:not(.active):not(.hide-sub-menu-large):hover .nav__sub-menu {
                @include breakpoint($menu-mobile-width up) {
                    display: flex;
                    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
                    transform: translate(-50%, 0);
                }
            }

            

            &.has-children.show-sub-menu-on-subpages.active .nav__sub-menu {
                @include breakpoint($menu-mobile-width up) {
                    display: flex;
                    z-index: 1;
                    transform: translate(-50%, 0);

                    
                }
            }

            &.has-children.show-sub-menu-on-subpages.active {
                @include breakpoint($menu-mobile-width up) {
                     // margin-bottom: 64px;//sub menu height

                }
                
           
            }

            //dla horyzontalnego submenu nalezy dodac klase .horizontal-sub-menu dla li
            &.has-children.horizontal-sub-menu,  &.has-children.hide-sub-menu-large  {
                position: initial;

                & > .nav__sub-menu {
                    flex-direction: row;
                    align-content: flex-start;
                    width: 100%;
                    justify-content: center;
                   

                    @include breakpoint($menu-mobile-width up) {}


                    & > li {
                       
                        flex-grow: 1;
                        @include breakpoint($menu-mobile-width up) { border: none;}
                    }

                    & > li > a {
                        background: transparent;
                        color: $white;
                        padding: 0.8rem 0;
                        min-width: initial;

                        @include breakpoint($menu-mobile-width) {}


                        @include breakpoint(1000px) {
                            // padding:17px 30px;
                        }
                    }

                    & > li > a:hover, & > li.active > a {
                        background: $black-lighter;
                    }
                }

                & > .nav__sub-menu.nav-down {
                    position: fixed;
                    top: 0;
                    bottom: initial;
                    transform: initial;
                    z-index: 100;
                    transform: translate(-50%,0);
                   
                }
               & > .nav__sub-menu.nav-up {
                   transition: all 0.3s ease;
                    top: -100%;
                    
                }

            }
        }
    }

    // mobile menu OPEN

    &__menu.is-open {
        @include breakpoint($menu-mobile-width down) {
            transform: translateY(100%);
            background: $header-background;	
            
          li > a {
              font-size:2rem;
         }
        }
    }

    // SUBMENU

    &__sub-menu {
        margin: 0 0;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        display: none;
        
       
        
        @include breakpoint($menu-mobile-width up) {
            position: absolute;
            left: 50%;
            top: 100%;
             z-index:-1;
             display: flex;
            transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
            // flex-direction: row;
            transform: translate(-50%, -102%);
            width: auto;
        }


        &.is-open {
          
             @include breakpoint($menu-mobile-width down) {
                display: block;
            }
            @include breakpoint($menu-mobile-width up) {
                // display: none;
            }
        }
    }



    &__sub-menu > li {
        text-align: center;
        
        background: $black;
        @include breakpoint($menu-mobile-width down){
            border-top: 1px solid #333;
        }
        &.first {
            border-top: 0;
        }
    }

    &__sub-menu > li > a {
        display: block;
        padding: 20px 30px;
        font-size: 0.75rem;
        letter-spacing: 0;
        text-transform: initial;
        color: $menu-sub-text-color;
        background: $menu-sub-background;

        @include breakpoint($menu-mobile-width) {
            padding: 18px 30px;
            white-space: nowrap;
            min-width: 170px;
        }

        &:hover, &:active, &:focus{
            background: $menu-sub-background-hover;
            color: $menu-sub-text-color-hover;
        }
    }

    
}


// mobile menu OPEN

.top-header{
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}
.top-header.is-open{ 
    background: $header-background-mobile;
     transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        .nav__menu{
            @include breakpoint($menu-mobile-width down) {
//                 transform: translateY(0);
            transform: translateY(0);
            transform: scale(1);
                opacity: 1;
                 background: $header-background-mobile;
                  li > a {
              font-size:2.2rem;
                 }
        }
    }
    .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
            background: $menu-text-color-mobile;
        }
        .nav-wrapper .nav{
            // background: $header-background-black; 
        }
}



.top-header--black{
    background: $header-background-black;



    .nav-wrapper{
        background: $header-background-black;
        
        &  > .nav{
         background: $header-background-black; 
             & > .nav__logo path {
                fill:$menu-text-color-hover;
            } 
             & > .nav__menu{
                background: $header-background-black;
                    @include breakpoint($menu-mobile-width down){
//                         border-bottom: 1px solid $menu-text-color; 
                    }
                & > li.top-item>a {
                    background: $header-background-black;
                    &:hover, &:active{
                        background: $black-lighter;
                    }
                } 
                 & > li > a {
                    color:$menu-text-color;
                     @include breakpoint($menu-mobile-width down){
                        color:$menu-text-color;
                     }
                    & > span:before{
                        background:$menu-text-color-white;
                        height: 2px;
                    }
                }
             }  
        }
        
         & .nav__sub-menu{
            li.first { 
                @include breakpoint($menu-mobile-width down) {
                    border-top:1px solid #000;
                  }
              }
              li {
                @include breakpoint($menu-mobile-width down) {
                    border-top:1px solid #000;
                    background:#333;
                }
               }
            }
             
        .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
            background: $menu-text-color;
        }
    }
}

// .top-header--white{
//     // background: $header-background-black;

//     &  .nav-wrapper{
//         // background: $header-background-black;
        
//         &  > .nav{
//         //  background: $header-background-black; 
//              & > .nav__logo .svg path{
//                 fill:$menu-text-color-white;
//             } 
//             & > .nav__menu{
//                 // background: $header-background-black;
//                     @include breakpoint($menu-mobile-width down){
//                         border-bottom: 1px solid $gray; 
//                           background: $header-background-black;
//                     }
//                 & > li.top-item>a {
//                     // background: $header-background-black;
//                     &:hover, &:active{
//                         background: $black-lighter;
//                     }
//                 } 
//                  & > li > a {
//                     color:$menu-text-color-white;
//                      @include breakpoint($menu-mobile-width down){
//                          border-top: 1px solid $gray;
//                          color:$menu-text-color;
//                      }
//                     & > span:before{
//                         // background:$menu-text-color-white;
//                         height: 1px;
//                     }
//                 }
//              }  
//              .hamburger-inner, .hamburger-inner::after, .hamburger-inner::before {
//                 background: $menu-text-color-white;
//             }
//         }
//     }
// }

// HAMBURGER

/*
* Hamburgers
* @description Tasty CSS-animated hamburgers
* @author Jonathan Suh @jonsuh
* @site https://jonsuh.com/hamburgers
* @link https://github.com/jonsuh/hamburgers
*/

// Settings
// ==================================================
$hamburger-padding-x: 0 !default;
$hamburger-padding-y: 0 !default;
$hamburger-layer-width: 27px !default;
$hamburger-layer-height: 2px !default;
$hamburger-layer-spacing: 5px !default;
$hamburger-layer-color: $menu-text-color-mobile !default;
$hamburger-layer-border-radius: 0 !default;
$hamburger-hover-opacity: 1 !default;
$hamburger-hover-transition-duration: 0.1s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;

// Hamburger
// ==================================================
.hamburger {
    padding: $hamburger-padding-y $hamburger-padding-x;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: $hamburger-hover-transition-duration;
    transition-timing-function: $hamburger-hover-transition-timing-function;

    // Normalize (<button>)
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    &:hover {
        @if $hamburger-hover-use-filter == true {
            filter: $hamburger-hover-filter;
        }
        @else {
            opacity: $hamburger-hover-opacity;
        }
    }

    &:focus {
        outline: none;
    }
}

.hamburger-box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: $hamburger-layer-height / -2;

    &,
    &::before,
    &::after {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height;
        background-color: $hamburger-layer-color;
        border-radius: $hamburger-layer-border-radius;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    &::before,
    &::after {
        content: "";
        display: block;
    }

    &::before {
        top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }

    &::after {
        bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
}

/*
* Spring
*/
.hamburger--spring {
    .hamburger-inner {
        top: $hamburger-layer-height / 2;
        transition: background-color 0s 0.13s linear;

        &::before {
            top: $hamburger-layer-height + $hamburger-layer-spacing;
            transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }

        &::after {
            top: $hamburger-layer-height * 2 + $hamburger-layer-spacing * 2;
            transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }

    &.is-active {
        .hamburger-inner {
            transition-delay: 0.22s;
            background-color: transparent;

            &::before {
                top: 0;
                transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
                transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(45deg);
            }

            &::after {
                top: 0;
                transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
                transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(-45deg);
            }
        }
    }
}

// end hamburger