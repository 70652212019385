.faq{
    background:white;
    position: relative;
    z-index: 111;
    margin-top:120px;
    padding-top:2rem;
    @include breakpoint($md){
      margin-top:120px;
    }

    h2{
      margin-top:4rem;
      font-size: 1.9rem;
    }
    h3{
      margin-top:3rem;
      
    }
    p{
      margin-bottom:2rem;
    }
}