
.footer{
    margin-top:10rem;
    &__address{
            font-style: normal;
            font-size: 1.4rem;
            line-height: 1.5em;
            @include breakpoint($md){ 
                font-size: 1.2rem;
            }
            @include breakpoint($lg){ 
                font-size: 1rem;
            }
  
    }
    &__logo{
        height:23px;
    }
    &__credits{
        text-align: right;
        color:#ccc;
        font-size: 0.8rem;
 
        margin-top:4rem;
        margin-bottom:2rem;
        @include breakpoint($md){ 
            font-size: 0.8rem;
        }
        @include breakpoint($lg){ 
            font-size: 0.65rem;
        }
        a{
            color:#ccc;
            transition: color ease 0.4s; 
        }
        a:hover{
            color:$secondary-color;
        }
    }
}
    