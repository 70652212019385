main.main-layout{

    // background: white;
    // max-width: 80ch;
    // margin: 0 auto;
    // padding: 3rem 3rem;

    p{
        font-weight: normal;
        font-size:1rem;
        margin-bottom:2rem;
    }

    ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
      }
      
      ol > li {
        display: table;
        counter-increment: item;
        margin-bottom: 1em;
        font-size:1rem;
        font-weight: normal;
       
      }
      ol > li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right:0.4em;   
      }


}


    
.white-content{
  background:white;
  position: relative;
  z-index: 111;
  margin-top:120px;
  padding-top:2rem;
  @include breakpoint($md){
    margin-top:120px;
  }
}
